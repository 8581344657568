import axios from '@/common/axios'
import qs from 'qs'

export function addFirst (data) {
  return axios({
    method: 'post',
    url: '/check/add',
    data: qs.stringify(data)
  })
}

export function deleteFirst (id) {
  return axios({
    method: 'delete',
    url: '/check/delete/' + id
  })
}

export function updateFirst (data) {
  return axios({
    method: 'put',
    url: '/check/update',
    data: qs.stringify(data)
  })
}

export function updateFirstAcceptance (data) {
  return axios({
    method: 'put',
    url: '/check/acceptance/update',
    data: qs.stringify(data)
  })
}

export function updateFirstReview (data) {
  return axios({
    method: 'put',
    url: '/check/review/update',
    data: qs.stringify(data)
  })
}

export function selectFirstInfo (id) {
  return axios({
    method: 'get',
    url: '/check/info/' + id
  })
}

export function selectFirstList (query) {
  return axios({
    method: 'get',
    url: '/check/list',
    params: query
  })
}
