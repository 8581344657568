<template>
  <div id="first">
    <el-dialog
      :title="firstFormTitle"
      width="1500px"
      :visible.sync="firstDialogVisible"
      :close-on-click-modal="false"
      @close="firstDialogClose"
    >
      <el-form
        ref="firstFormRef"
        :model="firstForm"
        inline
      >
        <el-form-item label="验收结论" prop="acceptanceConclusion">
          <el-input
            v-model="firstForm.acceptanceConclusion"
            placeholder="请输入验收结论"
            clearable
            :readonly="firstFormTitle === '入库初检详情'"
            :disabled="checkRole(['ZJL'])"
          />
        </el-form-item>
        <el-form-item label="复核结果" prop="reviewResult">
          <el-radio-group v-model="firstForm.reviewResult" :disabled="!checkRole(['ZJL'])">
            <el-radio :label="1">
              同意
            </el-radio>
            <el-radio :label="2">
              不同意
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="复核结论" prop="reviewConclusion">
          <el-input
            v-model="firstForm.reviewConclusion"
            placeholder="请输入复核结论"
            clearable
            :readonly="firstFormTitle === '入库初检详情'"
            :disabled="!checkRole(['ZJL'])"
          />
        </el-form-item>
      </el-form>
      <vxe-toolbar v-if="firstFormTitle !== '入库初检详情' && !checkRole(['ZJL'])">
        <template #buttons>
          <vxe-button @click="insertRowEvent()">
            新增
          </vxe-button>
        </template>
      </vxe-toolbar>
      <vxe-table
        ref="xTable"
        border
        resizable
        show-overflow
        keep-source
        :data="firstDetailList"
        :edit-config="{trigger: 'click', mode: 'cell'}"
      >
        <vxe-table-column type="seq" width="50" />
        <vxe-table-column
          field="productName"
          title="来料名称"
          width="150"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="spec"
          title="规格/型号"
          width="100"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="batchNumber"
          title="批号"
          width="100"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="productionDate"
          title="生产日期"
          width="150"
          :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
        />
        <vxe-table-column
          field="validPeriod"
          title="有效期"
          width="100"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="technicalIndex"
          title="技术指标"
          width="100"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="unit"
          title="单位"
          width="100"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="quantity"
          title="数量"
          width="100"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="number"
          title="件数"
          width="100"
          :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
        />
        <vxe-table-column
          field="exterior"
          title="外观情况"
          :edit-render="{
            name: '$select',
            props: {clearable: true},
            options: [
              {
                value: 1,
                label: '符合规定'
              },
              {
                value: 2,
                label: '不符合规定'
              }
            ]
          }"
        />
        <vxe-table-column
          field="samplingSituation"
          title="抽检情况"
          :edit-render="{
            name: '$select',
            props: {clearable: true},
            options: [
              {
                value: 1,
                label: '符合规定'
              },
              {
                value: 2,
                label: '不符合规定'
              }
            ]
          }"
        />
        <vxe-table-column
          v-if="firstFormTitle !== '入库初检详情' && !checkRole(['ZJL'])"
          title="操作"
          width="100"
        >
          <template #default="{ row }">
            <template>
              <vxe-button @click="removeRowEvent(row)">
                删除
              </vxe-button>
            </template>
          </template>
        </vxe-table-column>
      </vxe-table>
      <div slot="footer">
        <el-button @click="firstDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="firstFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="入库初检编号">
        <el-input v-model="searchForm.checkNumber" placeholder="请输入入库初检编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="firstPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="checkNumber" label="入库初检编号" />
      <el-table-column prop="acceptor" label="验收人" />
      <el-table-column prop="acceptanceConclusion" label="验收结论" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">同意</span>
          <span v-if="scope.row.reviewResult === 2">不同意</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewConclusion" label="复核结论" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button> -->
          <el-button
            v-if="scope.row.reviewResult !== 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="firstPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addFirst,
  deleteFirst,
  // updateFirst,
  updateFirstAcceptance,
  updateFirstReview,
  selectFirstInfo,
  selectFirstList
} from '@/api/purchase/first'

export default {
  data () {
    return {
      firstDialogVisible: false,
      firstFormTitle: '',
      firstForm: {
        id: '',
        checkDetailJson: '',
        acceptanceConclusion: '',
        reviewResult: '',
        reviewConclusion: ''
      },
      firstPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        checkNumber: ''
      },
      firstDetailList: []
    }
  },
  created () {
    selectFirstList(this.searchForm).then(res => {
      this.firstPage = res
    })
  },
  methods: {
    firstDialogClose () {
      this.$refs.firstFormRef.resetFields()
      this.firstDetailList = []
    },
    firstFormSubmit () {
      if (this.firstFormTitle === '入库初检详情') {
        this.firstDialogVisible = false
        return
      }
      this.$refs.firstFormRef.validate(async valid => {
        if (valid) {
          this.firstForm.checkDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.firstFormTitle === '新增入库初检') {
            await addFirst(this.firstForm)
          } else if (this.firstFormTitle === '修改入库初检') {
            if (this.checkRole(['ZJL'])) {
              await updateFirstReview(this.firstForm)
            } else {
              await updateFirstAcceptance(this.firstForm)
            }
          }
          this.firstPage = await selectFirstList(this.searchForm)
          this.firstDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.firstFormTitle = '新增入库初检'
      this.firstDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteFirst(row.id)
        if (this.firstPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.firstPage = await selectFirstList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.firstFormTitle = '修改入库初检'
      this.firstDialogVisible = true
      selectFirstInfo(row.id).then(async res => {
        this.firstForm.id = res.id
        this.firstForm.acceptanceConclusion = res.acceptanceConclusion
        this.firstForm.reviewResult = res.reviewResult
        this.firstForm.reviewConclusion = res.reviewConclusion
        this.firstDetailList = res.inCheckDetailList
      })
    },
    handleInfo (index, row) {
      this.firstFormTitle = '入库初检详情'
      this.firstDialogVisible = true
      selectFirstInfo(row.id).then(async res => {
        this.firstForm.id = res.id
        this.firstForm.acceptanceConclusion = res.acceptanceConclusion
        this.firstForm.reviewResult = res.reviewResult
        this.firstForm.reviewConclusion = res.reviewConclusion
        this.firstDetailList = res.inCheckDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectFirstList(this.searchForm).then(res => {
        this.firstPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectFirstList(this.searchForm).then(res => {
        this.firstPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectFirstList(this.searchForm).then(res => {
        this.firstPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
#first .el-dialog__body {
  margin: 0px 0px 20px 20px;
}
</style>
